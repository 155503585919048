const createNewModal = (modalId, html, modalSize) => {
  var maxZ = Math.max.apply(null,
      $('#modal-container .modal').map((e,modal) => {
        if ($(modal).css('position') != 'static')
          return parseInt($(modal).css('z-index')) || 1;
      }))

  const rawHtml = `<div class="modal fade" style="z-index: ${maxZ + 1}" id="${modalId}" tabindex="-1" role="dialog" aria-labelledby="appModalLabel" aria-hidden="true" style="background: 
    rgba(0,0,0,0.3);">
    <div class="modal-dialog modal-${modalSize || "md"} modal-dialog-centered" role="document">
      <div class="modal-content">
        ${html}
      </div>
    </div>
  </div>`

  $("#modal-container").append(rawHtml.trim());
  $(`#${modalId}`).on('shown.bs.modal', function () {
    $("#case_party_lawyer_ids, #case_attendee_lawyer_ids").select2("close")
    $('#case_party_salutation, #case_case_number, #case_attendee_salutation, #session_session_date, #location_company, #user_salutation, #assistant_salutation').focus();
  })
  $(`#${modalId}`).modal("show")
}

const replaceModalContent = (modalId, html) => {
  $(`#${modalId} .modal-dialog .modal-content`).html(html);
  $(`#${modalId}`).on('shown.bs.modal', function () {
    $("#case_party_lawyer_ids, #case_attendee_lawyer_ids").select2("close")
    $('#case_party_salutation, #case_case_number, #case_attendee_salutation, #session_session_date, #location_company, #user_salutation, #assistant_salutation').focus();
  })
  $(`#${modalId}`).modal("show")
}

export const showModal = function(modalId, html, modalSize) {
  if (document.getElementById(modalId)) {
    replaceModalContent(modalId, html)
  }
  else {
    createNewModal(modalId, html, modalSize)
  }
}

export const closeModal = function(modalId) {
  $(`#${modalId}`).modal("hide")
}
